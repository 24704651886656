import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";

import moment from "moment";
import Page from "../General/Page";
import PrintHeader from "../General/PrintHeader";
const PrintStockDetailByID = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));

  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [PageData, setPageData] = useState('');
  const [Stockitem, setStockitem] = useState('');
  const [isUpdated, setIsUpdated] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [totalRecords, setTotalRecords] = useState(0);

  let params = useParams();


  useEffect(() => {
    document.title = "Print Stock Item Details";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: params.id,
    };
    console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Stock/GetItemDetailByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        setPageData(response.data);
        setLoading(false);
        setTotalRecords(response.data.ListofDetails);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  // const renderLedger = () => {
  //   if (listOfLedger != null && listOfLedger.length > 0) {
  //     return listOfLedger.map((item, index) => {
  //       return (
  //         <tr key={index}>
  //           <td>{item.ID}</td>
  //           <td>{moment(item.Transaction_Date).format("DD/MM/YYYY")}</td>
  //           <td>{item.Description}</td>
  //           <td>{item.Item_In}</td>
  //           <td>{item.Item_Out}</td>
  //           <td>{item.Item_Balance}</td>
  //           <td>{item.Custodian}</td>
  //         </tr>
  //       );
  //     });
  //   } else {
  //     return (
  //       <tr>
  //         <td colSpan="7">
  //           <h4
  //             className="mt-3"
  //             style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
  //           >
  //             No Data Found
  //           </h4>
  //         </td>
  //       </tr>
  //     );
  //   }
  // };

  return (
    <>
      <Page orientation="portrait" size="a4">
        <PrintHeader title={''} />

        <table className="table table-sm table-print white-space-wrap fs-3">
          <tr>
            <td className="text-nowrap">
              <h3>
                {PageData?.ItemName?.Item_In > 0 ?
                  'Product Receipt Note '
                  :
                  'Product Issuance Note '
                }
              </h3>
            </td>
            <td className="text-nowrap text-end" style={{fontSize:'17px'}}>
              Dated: {moment(PageData?.ItemName?.Transaction_Date).format("DD/MM/YYYY")}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <span style={{ fontSize: '17px' }}>Item Code: {PageData.ItemDetail?.Stock_Code}</span>
              <br />
              <span style={{ fontSize: '17px' }}>Product Name:  {PageData.ItemDetail?.Item_Name}</span>
              <br />
              <span style={{ fontSize: '17px' }}>Register Type: {PageData.ItemDetail?.Register_Type}</span>
              <br />
              <div style={{ marginLeft: '205pt', fontSize: '17px' }}>
                {PageData?.ItemName?.Item_In > 0 ?
                  'Quantity Receipted'
                  :
                  'Quantity Issuanced'
                }
                <b style={{ fontSize: '48px' }}>
                  {PageData?.ItemName?.Item_In > 0 ?
                    (PageData?.ItemName?.Item_In)
                    :
                    (PageData?.ItemName?.Item_Out)
                  }
                </b>
                <span> {PageData?.ItemDetail?.MeasureUnit}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <br />
              <table style={{ width: '70%' }}>
                <tbody>
                  <tr>
                    <td style={{ fontSize: '17px' }}>Description</td>
                    <td style={{ fontSize: '17px' }}>{PageData?.ItemName?.Description}</td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: '17px' }}>Custodian</td>
                    <td style={{ fontSize: '17px' }}>{PageData?.ItemName?.Custodian}</td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: '17px' }}>Depreciation Rate</td>
                    <td style={{ fontSize: '17px' }}>{PageData?.ItemDetail?.DepreciationRate}</td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: '17px' }}>Register Ref</td>
                    <td style={{ fontSize: '17px' }}>{PageData?.ItemDetail?.Register_Ref}</td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: '17px' }}>Current Book Value</td>
                    <td style={{ fontSize: '17px' }}>{PageData?.ItemDetail?.CurrentBookValue}</td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <div>
                <div className="text-left" style={{ fontSize: '17px' }}> Available Balance: {PageData.ItemDetail?.Current_Balance}</div>
                <div className="text-right mt-4" style={{ fontSize: '17px' }}>
                  Signature of Stock Manager
                </div>
              </div>

              <br />

            </td>
          </tr>
          <hr />
        </table>

      </Page >
    </>
  );
};

export default PrintStockDetailByID;
