import ReportsMenu from "./ReportsMenu";
import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import SessionsDropDown from "../Shared/SessionsDropDown";
import WingsDropDown from "../Shared/WingsDropDown";
import ClassesDropDown from "../Shared/ClassesDropDown";
import { toast, ToastContainer } from "react-toastify";
import { NavLink, Link } from "react-router-dom";
import { scrollToTop } from "../Shared/ScrollOnTop";
import moment from "moment";
import MonthsDropDown from "../Shared/MonthsDropDown";
import YearsDropDown from "../Shared/YearsDropDown";
import { calculateColumnTotal } from "../General/Utility";

const StudentStrengthReport = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  //const [DefaultSession, setDefaultSession] = useState(localStorage.getItem("DefaultSession"));

  const [showFilters, setShowFilters] = useState(true);

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setlistOfLedger] = useState([]);
  const [listOfLedger1, setlistOfLedger1] = useState([]);
  const [listOfLedger2, setlistOfLedger2] = useState([]);

  const [validated, setValidated] = useState(false);

  const [totalsections, settotalsections] = useState(0);
  const [totalstrength, settotalstrength] = useState(0);

  const [WingId, setWingId] = useState(0);
  const [SessionId, setSessioinId] = useState(
    localStorage.getItem("DefaultSession")
  );
  const [ClassId, setClassId] = useState(0);
  const [ReportType, setReportType] = useState(1);
  const [StartDate, setStartDate] = useState();
  const [isUpdated, setIsUpdated] = useState(false);
  const [ReportMonth, setReportMonth] = useState(moment().format("M"));
  const [ReportYear, setReportYear] = useState(moment().format("YYYY"));

  useEffect(() => {
    document.title = "Student Strength Report";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      SessionID: SessionId,
      Wing_Id: WingId,
      Class_Id: ClassId,
      ReportType: ReportType,
      StartDate: StartDate,
      SelectedMonth: ReportMonth,
      FYear: ReportYear,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Reports/GetStudentStrengthReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
         console.log(response.data);
        if (ReportType == 1 && WingId == 0) {
          setlistOfLedger(response.data.ListofRecords);
        } else if (ReportType == 1 && WingId > 0) {
          setlistOfLedger1(response.data.ListofRecords);
        } else if (ReportType == 2 && WingId == 0) {
          setlistOfLedger(response.data.ListofRecords);
        } else if (ReportType == 2 && WingId > 0) {
          setlistOfLedger1(response.data.ListofRecords);
        } else if (ReportType == 3 && WingId > 0 && ClassId > 0) {
          setlistOfLedger2(response.data.ListofRecords);
        }
        setLoading(false);
        // settotalsections(response.data.totalsections);
        // settotalstrength(response.data.totalstrength);
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [
    WingId,
    ClassId,
    SessionId,
    ReportType,
    StartDate,
    ReportMonth,
    ReportYear,
  ]);

  const renderLedger = () => {
    //console.log(listOfLedger);
    if (listOfLedger != null && listOfLedger.length > 0) {
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.Serial_No}</td>
            <td>{item.Wing_Name}</td>
            <td>{item.Class_Name}</td>
            <td>{item.TotalSections}</td>
            <td>{item.TotalStudents}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="5">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const renderLedger1 = () => {
    if (listOfLedger1 != null && listOfLedger1.length > 0) {
      return listOfLedger1.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.Serial_No}</td>
            <td>{item.Wing_Name}</td>
            <td>{item.Class_Name}</td>
            <td>{item.Section_Name}</td>
            <td>{item.TotalStudents}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="5">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  const renderLedger2 = () => {
    if (listOfLedger2 != null && listOfLedger2.length > 0) {
      return listOfLedger2.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.Serial_No}</td>
            <td>{item.Class_Name}</td>
            <td>{item.Section_Name}</td>
            <td>{item.TotalStudents}</td>
            <td>{item.Present}</td>
            <td>{item.Absent}</td>
            <td>{item.CasualLeave}</td>
            <td>{item.LateCommer}</td>
            <td></td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="9">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <ReportsMenu />
      <ToastContainer />
      <div className="page-head">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/Dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/Reports/ReportsDashboard">Reports</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Students Strength Report
                  </li>
                </ol>
              </nav>
              <div className="page-title">Students Strength Report</div>
            </div>
            <div className="col-md-6">
              <div className="page-right-actions">
                <button
                  onClick={() => {
                    setShowFilters((prevIsActive) => !prevIsActive);
                  }}
                  className="button button-white button-icon"
                >
                  {showFilters ? "Hide Filters" : "Show Filters"}
                  <i className="ri-filter-3-line"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`collapse ${showFilters ? "show" : ""} `}
        id="collapseFilteres"
      >
        <div className="page-content">
          <div className="container">
            <div className="card mb-2">
              <div className="card-body p-md-4">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="">
                        Select Session:
                      </label>
                      <select
                        className="form-select"
                        onChange={(e) => setSessioinId(e.target.value)}
                        name="SessionID"
                        defaultValue={SessionId}
                      >
                        <option value="0">---All---</option>
                        <SessionsDropDown selectedSession={SessionId} />
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label>Select Wing:</label>
                      <select
                        name="Wing_Id"
                        onChange={(e) => setWingId(e.target.value)}
                        className="form-select"
                      >
                        <option value="0">---All---</option>
                        <WingsDropDown />
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="" className="">
                        Fetch Data From:
                      </label>
                      <select
                        className="form-select"
                        name="reportType"
                        onChange={(e) => setReportType(e.target.value)}
                        defaultValue={ReportType}
                      >
                        <option value="1">Active Status</option>
                        <option value="2">Fee Posted</option>
                        <option value="3">Attendance Record</option>
                      </select>
                    </div>
                  </div>
                  {ReportType == 2 && (
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="" className="">
                          Select Month:
                        </label>
                        <select
                          name="ReportMonth"
                          defaultValue={ReportMonth}
                          onChange={(e) => setReportMonth(e.target.value)}
                          className="form-select"
                        >
                          <option value="">Please Select</option>
                          <MonthsDropDown />
                        </select>
                      </div>
                    </div>
                  )}

                  {ReportType == 2 && (
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="" className="">
                          Select Year:
                        </label>
                        <select
                          name="ReportYear"
                          onChange={(e) => setReportYear(e.target.value)}
                          className="form-select"
                        >
                          <option value="">Please Select</option>
                          <YearsDropDown currentYearSelectd={ReportYear} />
                        </select>
                      </div>
                    </div>
                  )}

                  {ReportType == 3 && (
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label htmlFor="" className="">
                          Select Class:
                        </label>
                        <select
                          className="form-select"
                          onChange={(e) => setClassId(e.target.value)}
                          name="Class_ID"
                        >
                          <option value="0">Please Select</option>
                          {SessionId != 0 && WingId != 0 && (
                            <ClassesDropDown
                              SessionID={SessionId}
                              WingID={WingId}
                            />
                          )}
                        </select>
                      </div>
                    </div>
                  )}

                  {ReportType == 3 && (
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="" className="">
                          Date of Attendance:
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(e) => setStartDate(e.target.value)}
                          max={moment().format(config.date_format_input)}
                          min={moment()
                            .subtract(10, "months")
                            .format(config.date_format_input)}
                          required
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-md-2 offset-md-7 text-end">
                    <div className="form-group text-end">
                      <button
                        type="submit"
                        value="Search"
                        className="w-100 button button-primary mt-4"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="card">
            <div className="card-body">
              {ReportType == 1 && WingId == 0 ? (
                <table className="table table-theme">
                  <thead>
                    <tr>
                      <th>Serial No</th>
                      <th>Wing Name.</th>
                      <th>Class Name</th>
                      <th>Total Section</th>
                      <th>Total Strength</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colspan="5">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {renderLedger()}
                        <tr>
                          <td colspan={3} className="text-end">
                            Total:
                          </td>
                          <td>
                            {calculateColumnTotal(
                              listOfLedger,
                              "TotalSections"
                            )}
                          </td>
                          <td>
                            {calculateColumnTotal(
                              listOfLedger,
                              "TotalStudents"
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              ) : ReportType == 1 && WingId > 0 ? (
                <table className="table table-theme">
                  <thead>
                    <tr>
                      <th>Serial No</th>
                      <th>Wing Name.</th>
                      <th>Class Name</th>
                      <th>Section Name</th>
                      <th>Total Strength</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colspan="5">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {renderLedger1()}
                        <tr>
                          <td colspan={4} className="text-end">
                            Total:
                          </td>
                          <td>
                            {calculateColumnTotal(
                              listOfLedger1,
                              "TotalStudents"
                            )}
                          </td>
                          
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              ) : ReportType == 2 && WingId == 0 ? (
                <table className="table table-theme">
                  <thead>
                    <tr>
                      <th>Serial No</th>
                      <th>Wing Name.</th>
                      <th>Class Name</th>
                      <th>Total Section</th>
                      <th>Total Strength</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colspan="5">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {renderLedger()}
                        <tr>
                          <td colspan={3} className="text-end">
                            Total:
                          </td>
                          <td>
                            {calculateColumnTotal(
                              listOfLedger,
                              "TotalSections"
                            )}
                          </td>
                          <td>
                            {calculateColumnTotal(
                              listOfLedger,
                              "TotalStudents"
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              ) : ReportType == 2 && WingId > 0 ? (
                <table className="table table-theme">
                  <thead>
                    <tr>
                      <th>Serial No</th>
                      <th>Wing Name.</th>
                      <th>Class Name</th>
                      <th>Section Name</th>
                      <th>Total Strength</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colspan="5">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {renderLedger1()}
                        <tr>
                          <td colspan={4} className="text-end">
                            Total:
                          </td>
                          <td>
                            {calculateColumnTotal(
                              listOfLedger1,
                              "TotalStudents"
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              ) : ReportType == 3 && WingId > 0 ? (
                <table className="table table-theme">
                  <thead>
                    <tr>
                      <th>Serial No</th>
                      <th>Class Name</th>
                      <th>Section Name</th>
                      <th>Total Strength</th>
                      <th>Present</th>
                      <th>Absent</th>
                      <th>Casual Leave</th>
                      <th>Late Commer</th>
                      <th>Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colspan="9">
                          <Spinner animation="border" role="status"></Spinner>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {renderLedger2()}
                        <tr>
                          <td colspan={3} className="text-end">
                            Total:
                          </td>
                          <td>
                            {calculateColumnTotal(
                              listOfLedger2,
                              "TotalStudents"
                            )}
                          </td>
                          <td>
                            {calculateColumnTotal(
                              listOfLedger2,
                              "Present"
                            )}
                          </td>
                          <td>
                            {calculateColumnTotal(
                              listOfLedger2,
                              "Absent"
                            )}
                          </td>
                          <td>
                            {calculateColumnTotal(
                              listOfLedger2,
                              "CasualLeave"
                            )}
                          </td>
                          <td>
                            {calculateColumnTotal(
                              listOfLedger2,
                              "LateCommer"
                            )}
                          </td>
                          <td></td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              ) : (
                <p>Select Proper Filters to display data</p>
              )}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="card mt-2 text-end">
            <div className="card-body p-md-4">
              <Link
                to={`/Reports/PrintStudentStrengthReports?SessionId=${SessionId}&WingId=${WingId}&ClassId=${ClassId}&Reporttype=${ReportType}&StartDate=${StartDate}&Reportmonth=${ReportMonth}&Reportyear=${ReportYear}`}
                target="_blank"
                className="button button-primary"
              >
                Print Report
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentStrengthReport;
