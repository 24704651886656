import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import SetupMenu from './SetupMenu';
import WingsDropDown from '../Shared/WingsDropDown';
import SessionsDropDown from "../Shared/SessionsDropDown";
import Pagination from "../General/Pagination";
import ClassesDropDown from "../Shared/ClassesDropDown";
import { scrollToTop } from '../Shared/ScrollOnTop';

const ManageSection = () => {
    const urlParams = new URLSearchParams(window.location.search);

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));

    const [WingID, setWingId] = useState(0);
    const [SessionID, setSessionId] = useState(localStorage.getItem("DefaultSession"));
    const [listOfLedger, setLedger] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ClassID, setClassId] = useState(urlParams.get('Class_Id'));

    // pagination 
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    const [WingName, setWingName] = useState("");
    const [ClassName, setClassName] = useState("");
    const [Wing_ID, setWing_ID] = useState("");

    const [urlWingName, seturlWingName] = useState("");
    const [urlClassName, seturlClassName] = useState("");
    const [urlClass_Id, seturlClass_Id] = useState("");
    const [urlSession, seturlSession] = useState("");
    const [urlWing_ID, seturlWing_ID] = useState("");

    // Retrieve individual parameter values

useEffect(()=>{

    
            seturlWingName(urlParams.get('Wing'));
            seturlClass_Id(urlParams.get('Class_Id'));
            seturlSession(urlParams.get('Session'));
            seturlWing_ID(urlParams.get('Wing_ID'));
        //    setClassId(urlParams.get('Class_Id'));
            setWing_ID(urlParams.get('Wing_ID'));
},[])
    

    useEffect(() => {
        document.title = "Manage Sections";

        setLoading(true)

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            PageNo: pageNo,
            PageSize: pageSize,
            Class_Id: ClassID,

        }

        //console.log(data)

        var api_config = {
            method: 'post',
            url: config.base_url + 'Sections/GetAllSections',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setLedger(response.data.ListofRecords);
                    setTotalRecords(response.data.totalRecords)
        setLoading(false)

                }
                else {
                    setLedger(null);
                    setTotalRecords(0)
                }

            })
            .catch(function (error) {
                //console.log(error);
            });


    }, [pageNo, pageSize, WingID, SessionID, ClassID]);



    // useEffect(() => {

    //     document.title = "Manage Sections";

    //     // Get the URL parameters
      

    //         const data = {
    //             AccessKey: AccessKey,
    //             UserID: UserID,
    //             MemberType: MemberType,
    //             PageNo: pageNo,
    //             PageSize: pageSize,
    //             Class_Id: ClassID,

    //         }

    //         var api_config = {
    //             method: 'post',
    //             url: config.base_url + 'Sections/GetAllSections',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             data: data
    //         };

    //         axios(api_config)
    //             .then(function (response) {
    //                 //console.log(response.data);
    //                 if (response.data.status_code == 1) {
    //                     setLedger(response.data.ListofRecords);
    //                     setTotalRecords(response.data.totalRecords)
    //                 }
    //                 else {
    //                     setLedger(null);
    //                     setTotalRecords(0)
    //                 }

    //             })
    //             .catch(function (error) {
    //                 //console.log(error);
    //             });
        

    // }, [pageNo, pageSize, Wing_ID, SessionID, ClassID]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.Section_ID}</td>
                            <td>{item.Section_Code}</td>
                            <td>{item.Section_Short_Name}</td>
                            <td>{item.Section_Name}</td>
                            <td>{item.Section_Strength_Limit}</td>
                            <td>{item.SelectedClass.Class_Name}</td>
                            <td >{item.Section_Teacher_Name}</td>
                            <td className="text-center">
                                <div className="dropdown dropdown-table-actions">
                                    <Link href="javascript:;" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" className="">
                                        <i className="ri-more-fill"></i>
                                    </Link>
                                    {/* Class_Id=${Class}&FYear=${SelectedYear}&SelectedMonth=${SelectedMonth}&Wing=${WingName}&className=${ClassName} */}
                                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                        <li><Link to={`/Setup/UpdateSection/${item.Section_ID} `} className="dropdown-item"><i className="ri-edit-line"></i> Edit</Link></li>
                                        <li><Link onClick={(e) => deleteSection(item.Section_ID)} className="red dropdown-item"><i className="ri-delete-bin-line"></i>Delete</Link></li>
                                        <li><Link to={`/reports/SectionWiseList/${item.Section_ID}`} target="_blank" className="dropdown-item"><i className="ri-printer-line"></i>Print Student List</Link></li>
                                        <li><Link to={`/PrintReports/RPTAwardList/${item.Section_ID}`} target="_blank" className="dropdown-item"><i className="ri-printer-line"></i>Print Award List</Link></li>
                                        <li><Link to={`/Setup/PrintAttendenceSheet/${item.Section_ID}`} target="_blank" className="dropdown-item" ><i className="ri-printer-line"></i>Print Attendence Sheet</Link></li>
                                        <li><Link to={`/Setup/Absenteelist/${item.Section_ID}`} target="_blank" className="dropdown-item"><i className="ri-printer-line"></i>Print Absentee List</Link></li>
                                        <li><Link to={`/Setup/PrintBioDataFormsforSection/${item.Section_ID}`} target="_blank" className="dropdown-item"><i className="ri-printer-line"></i>Print BioData Forms</Link></li>
                                        <li><Link to={`/Setup/PrintStudentCards/${item.Section_ID}`} target="_blank" className="dropdown-item"><i className="ri-printer-line"></i>Print Student Cards</Link></li>
                                        <li><Link to={`/Setup/PrintTestPerforma/${item.Section_ID}`} target="_blank" className="dropdown-item"><i className="ri-printer-line"></i>Print Test Performa</Link></li>
                                        <li><Link to={`/Setup/OralAssessmentReport1/${item.Section_ID}`} target="_blank" className="dropdown-item"><i className="ri-printer-line"></i>Print Oral Assessment Report 1</Link></li>
                                        <li><Link to={`/Setup/OralAssessmentReport1/${item.Section_ID}`} target="_blank" className="dropdown-item"><i className="ri-printer-line"></i>Print Oral Assessment Report 1 Urdu</Link></li>
                                        <li><Link to={`/Setup/OralAssessmentReport2/${item.Section_ID}`} target="_blank" className="dropdown-item"><i className="ri-printer-line"></i>Print Oral Assessment Report 2 Urdu</Link></li>
                                        <li><Link to={`/setup/computer-practical/${item.Section_ID}`} target="_blank" className="dropdown-item"><i className="ri-printer-line"></i>Computer Practical</Link></li>
                                        <li><Link to={`/setup/test-report/${item.Section_ID}`} target="_blank" className="dropdown-item"><i className="ri-printer-line"></i>Test Report</Link></li>
                                        <li><Link to={`/setup/public-speaking-assessment/${item.Section_ID}`} target="_blank" className="dropdown-item"><i className="ri-printer-line"></i>Public Speaking Assessment</Link></li>
                                        <li><Link to={`/setup/sap-test-result/${item.Section_ID}`} target="_blank" className="dropdown-item"><i className="ri-printer-line"></i>SAP Test Result</Link></li>                                      
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="8"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }
    const deleteSection = (ID) => {

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            Section_Id: ID
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Sections/DeleteSectionByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setIsUpdated(true);
                }
                setIsUpdated(false);

            })
            .catch(function (error) {
                //console.log(error);
            });
    }

    


    return (
        <>
            <SetupMenu />
            <ToastContainer />
            <div className="page-head">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><NavLink onClick={scrollToTop} to="/Dashboard">Dashboard</NavLink></li>
                                    <li className="breadcrumb-item"> <NavLink onClick={scrollToTop} to="/Setup/SetupDashboard">Setup</NavLink></li>
                                    <li className="breadcrumb-item active" aria-current="page">Manage Sections</li>
                                </ol>
                            </nav>
                            <div className="page-title">Manage Sections</div>
                        </div>
                        <div className="col-md-6">
                            <div className="page-right-actions">
                                <NavLink onClick={scrollToTop} to="/Setup/AddSection" className="button button-white button-icon">
                                    Add Section
                                    <i className="ri-add-line"></i>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-content">
                <div className="container">
                    <div className="card mb-2">
                        <div className="card-body p-md-4">
                            <div className="row gy-3 align-items-center">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Select Session:</label>
                                        <select onChange={(e) => setSessionId(e.target.value)}
                                            className="form-select" required>
                                            <option value="">Please Select</option>
                                            <SessionsDropDown selectedSession={SessionID} />
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Select Wing:</label>
                                        <select className="form-select" required
                                            onChange={(e) => {
                                                setWing_ID(e.target.value)
                                                const selectedText = e.target.options[e.target.selectedIndex].text;
                                                setWingName(selectedText);
                                            }}>
                                            <option value="">Please Select</option>
                                            <WingsDropDown selectedWingId={Wing_ID} />
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Select Class:</label>
                                        <select className="form-select" required
                                            onChange={(e) => {
                                                setClassId(e.target.value)
                                                const selectedText = e.target.options[e.target.selectedIndex].text;
                                                setClassName(selectedText);
                                            }}
                                        >
                                            <option value="">Please Select</option>
                                            {
                                                SessionID != 0 && Wing_ID != 0 &&
                                                <ClassesDropDown selectedClassId={ClassID} SessionID={SessionID} WingID={Wing_ID} />
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="counting mt-4 text-end">
                                        <span className="">{totalRecords} </span> Records Found!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body table_card">
                            <div className="">
                                <table className="table table-theme table-hover">
                                    <thead>
                                        <tr>
                                            <th>
                                                Section ID
                                            </th>
                                            <th>
                                                Section Code
                                            </th>
                                            <th>
                                                Section Short Name
                                            </th>
                                            <th>
                                                Section Name
                                            </th>
                                            <th>
                                                Strength Limit
                                            </th>
                                            <th>
                                                Class Name
                                            </th>
                                            <th>
                                                Teacher Name
                                            </th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loading ?
                                                <tr>
                                                    <td className="text-center" colspan="8">
                                                        <Spinner animation="border" role="status"></Spinner>
                                                    </td>
                                                </tr>
                                                :
                                                <>
                                                    {renderLedger()}
                                                </>

                                        }
                                    </tbody>
                                </table>
                                {
                                    totalRecords > pageSize &&
                                    <div className="pagination-wrap">
                                        <div className="row align-items-center">
                                            <div className="col-md-2 form_default">
                                                <div className="d-flex align-items-center">
                                                    <label className="me-2" style={{ fontSize: "15px" }}>Page Size</label>
                                                    <div className="form-group">
                                                        <select className="form-select"
                                                            style={{ width: "100px" }}
                                                            onChange={(e) => {
                                                                setPageNo(1);
                                                                setPageSize(parseInt(e.target.value));
                                                            }}>
                                                            <option value="10" selected>10</option>
                                                            <option value="20">20</option>
                                                            <option value="50">50</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <Pagination
                                                pageNo={pageNo}
                                                pageSize={pageSize}
                                                totalRecords={totalRecords}
                                                onPageChange={handlePageChange}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                    </div>
                        </div>
                        <div className="card mt-md-3">
            <div className="card-body">
              <div className="row justify-content-end">
                <div className="col-md-2">
                  <NavLink
                    target="_blank"
                    to={`/Setup/PrintManageSections/?class_id=${ClassID}`}
                    className="button button-primary w-100"
                  >
                    Print This Report
                  </NavLink>
                </div>
       
              </div>
            </div>
          </div>
                </div>
            </div >
        </>
    );
}

export default ManageSection;
