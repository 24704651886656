import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import { useParams } from "react-router-dom";
import imgLogo from "../../assets/images/logo.png";

import Page from "../General/Page";

const  PrintAttendenceSheet = (props) => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listOfLedger, setLedger] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [selectedMonth, setselectedMonth] = useState("");

  let params = useParams();

  // Get the current month and number of days
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

  const [selectWing, setselectWing] = useState("");
  const [selectClass, setselectClass] = useState("");
  const [selectSection, setselectSection] = useState("");

  // Generate an array of days in the month
  const monthDays = Array.from(
    { length: daysInMonth },
    (_, index) => index + 1
  );

  // Generate an array of day names for the current month
  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  useEffect(() => {
    document.title = "Monthly Attendence Register";

    setIsUpdated(false);
    setLoading(true);

    setselectedMonth(moment().format("MMMM"));

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: 1,
      pageSize: 2000,
      Section_Id: props.Section_ID ? props.Section_ID : params.id,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Student/SearchStudents",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    //console.log(data);
    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
        setselectWing(
          response.data.ListofRecords[0].SelectedClass?.SelectedWing?.Wing_Name
        );
        setselectClass(
          response.data.ListofRecords[0].SelectedClass?.Class_Name
        );
        setselectSection(
          response.data.ListofRecords[0].SelectedSection?.Section_Name
        );
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [props.Section_ID]);

 

  const renderEmptyRows = (count) => {
    return Array.from({ length: count }, (_, index) => (
      <tr key={index}>
        <td><span className="opacity-0">www</span></td>
        <td></td>
        <td></td>
        {monthDays != null && monthDays.map((day) => (
          <React.Fragment key={day}>
            <td></td>
            <td></td>
          </React.Fragment>
        ))}
        <td></td>
      </tr>
    ));
  };
  const emptyRows = renderEmptyRows(6);
  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => (
        <tr key={index}>
          <td style={{ fontSize: "10px" }}>{count++}</td>
          <td style={{ whiteSpace: "nowrap", fontSize: "11px" }}>
            {item.Registration_No}
          </td>
          <td style={{ whiteSpace: "nowrap", fontSize: "11px" }}>
            {item.Student_Name}
          </td>
          {monthDays != null && monthDays.map((day) => (
            <React.Fragment key={day}>
              <td></td>
              <td></td>
            </React.Fragment>
          ))}
          <td></td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan={7}>
            <h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <Page orientation="landscape" size="legal">
        <table className="table table-sm table-bordered table-print white-space-wrap">
          <div className="report-header">
            <table style={{ width: "100%" }}>
              <tr style={{ border: "none" }}>
                <td style={{ width: "50%" }}>
                  <div className="logo">
                    <img src={imgLogo} alt="logo" />
                  </div>
                </td>
                <td
                  style={{
                    textAlign: "right",
                    fontSize: "10pt",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <b>Printed on: </b>
                  <span>
                    {moment().format("MMMM Do YYYY, h:mm:ss a")}
                  </span>
                </td>
              </tr>
            </table>
          </div>
          <h3 style={{ fontSize: "13pt" }}>
            Monthly Attendence Register For the Month of {selectedMonth} {selectWing}-{selectClass}-
            {selectSection}
          </h3>
        </table>
        <table className="table table-sm table-bordered table-print white-space-wrap">

          <thead>
            <tr>
              <th>Sr#</th>
              <th className="text-nowrap">Reg. No.</th>
              <th className="text-nowrap ">Student's Name</th>

              {/* Render the table headers for each day */}
              {monthDays.map((day) => (
                <th
                  key={day}
                  colSpan={2}
                  style={{ width: "18px", padding: "0" }}
                >
                  {day} <br />
                  {
                    dayNames[
                    new Date(
                      currentYear,
                      currentMonth,
                      day
                    ).getDay()
                    ]
                  }
                </th>
              ))}

              <th>Total</th>
            </tr>

          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colspan="70">
                  <Spinner animation="border" role="status"></Spinner>
                </td>
              </tr>
            ) : (
              <>
                {renderLedger()}
                {emptyRows}
              </>
            )}
          </tbody>
        </table>
        <div>
          <table
            className="table table-sm table-bordered table-print"
            style={{
              borderCollapse: "collapse",
              border: "transparent",
            }}
          >
            <tbody>
              <tr>
                <td style={{ width: "450px%" }}>
                  No.of Students at the beginning of the month
                </td>
                <td style={{ width: "15%" }}>
                  __________________________________________________________
                </td>
                <td style={{ width: "450px%" }}>
                  No of school meetings since
                  <span>June</span>
                  1st.
                </td>
                <td style={{ width: "15%" }}>
                  __________________________________________________________
                </td>
              </tr>
              <tr>
                <td>No. of Students struck off during the month</td>
                <td>
                  __________________________________________________________
                </td>
                <td>No of school meetings during the month</td>
                <td>
                  __________________________________________________________
                </td>
              </tr>
              <tr>
                <td>No. of Students admitted during the month</td>
                <td>
                  __________________________________________________________
                </td>
                <td>Total No. of attendance during the month.</td>
                <td>
                  __________________________________________________________
                </td>
              </tr>
              <tr>
                <td>No. of Students at the end of the month</td>
                <td>
                  __________________________________________________________
                </td>
                <td>Average daily attendance during the month</td>
                <td>
                  __________________________________________________________
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="table table-sm table-bordered table-print mt-5"
            style={{
              borderCollapse: "collapse",
              border: "transparent",
            }}
          >
            <tbody>
              <tr>
                <th style={{ width: "33.3333%" }} className="">
                  Teacher Incharge
                </th>
                <th
                  style={{ width: "33.3333%" }}
                  className="text-center"
                >
                  Checker
                </th>
                <th
                  style={{ width: "33.3333%" }}
                  className="text-end"
                >
                  Head of Wing
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </Page>
    </>
  );
};

export default PrintAttendenceSheet;
