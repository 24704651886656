import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import PrintHeader from '../General/PrintHeader';
import { useNavigate, useParams } from 'react-router-dom';
import Page from "../General/Page";

const ComputerPractical = (props) => {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listOfLedger, setLedger] = useState([]);


    let params = useParams();

    

    useEffect(() => {

        document.title = "Computer Practical Report";

        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            pageNo: 1,
            pageSize: 2000,
            Section_Id:  props.Section_ID ? props.Section_ID : params.id,
        };

       //console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Student/SearchStudents',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(data)
        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                setLedger(response.data.ListofRecords);
                setLoading(false);
                setTotalRecords(response.data.totalRecords);

            })
            .catch(function (error) {
                //console.log(error);
            });

    }, []);


    const renderLedger = () => {

        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return (
                listOfLedger.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                {count++}
                            </td>
                            <td>{item.Student_Name}</td>
                            <td>{item.Father_Name}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    )
                })
            )
        }
        else {
            return (
                <tr>
                    <td colspan="7"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
                </tr>
            )
        }

    }

    return (
        <>
            <Page orientation="portrait" size="a4" className='p-0'>
                <table className="table table-sm table-bordered table-print white-space-wrap">
                    <thead>
                        <tr>
                            <th colSpan={7}>
                                <PrintHeader title={`Computer Practical ____________________ 
                                ${listOfLedger[0]?.SelectedClass?.SelectedWing?.Wing_Name}-
                                ${listOfLedger[0]?.SelectedClass?.Class_Name}-
                                ${listOfLedger[0]?.SelectedSection?.Section_Name}`} />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                       
                        <tr>
                            <th>Sr#</th>
                            <th className="text-nowrap">Student's Name</th>
                            <th className="text-nowrap">Father's Name</th>
                            <th>Practical (20)</th>
                            <th>Viva (05)</th>
                            <th>Total (25)</th>
                            <th style={{width:'200px'}}>Remarks</th>
                        </tr>
                        {
                            loading ?
                                <tr>
                                    <td className="text-center" colspan="7">
                                        <Spinner animation="border" role="status"></Spinner>
                                    </td>
                                </tr>
                                :
                                <>
                                  {renderLedger()}
                                </>
                        }

                    </tbody>
                </table>
            </Page >
        </>
    );
}

export default ComputerPractical;
